import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../components/shared/Layout';
dayjs.locale('nl');
import nightsData from '../data/nightsData';

const PitchNights2021Page = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto">
            <h1>Pitch Nights 2021</h1>
            <h2 className="mb-16">Belangrijkste info over de Pitch nights:</h2>

            {nightsData.map((night) => (
              <div
                key={night.title}
                className="my-8 py-8 border-t border-primary"
              >
                <a id={night.id} href={`#${night.id}`}>
                  <h3>{night.title}</h3>
                </a>
                <p className="text-xl mb-4">
                  <strong>
                    {dayjs(night.eventDate).format(
                      'dddd D MMMM YYYY - HH:mm uur'
                    )}
                  </strong>
                </p>
                <ul className="text-base">
                  <li className="mb-2">
                    Deadline{' '}
                    <Link to="/frequently-asked-questions#trustee">
                      aanmelden als trustee
                    </Link>
                    :{' '}
                    <strong>
                      {dayjs(night.eventDate)
                        .subtract(14, 'day')
                        .format('D MMMM YYYY - HH:mm uur')}
                    </strong>
                    <br />
                    <p className="text-sm mb-0">
                      Er zijn nog {night.trustees_available} trustee plekken
                      beschikbaar
                    </p>
                  </li>
                  <li className="mb-2">
                    Deadline{' '}
                    <Link to="/frequently-asked-questions#idea">
                      indienen pitch
                    </Link>
                    :{' '}
                    <strong>
                      {dayjs(night.eventDate)
                        .subtract(7, 'day')
                        .format('D MMMM YYYY - HH:mm uur')}
                    </strong>
                  </li>
                  <li className="mb-2">
                    Locatie: Online Google Meet (for trustees) &amp; Youtube
                    Live (for public)
                  </li>
                </ul>
                <p className="text-base">
                  De Pitch Night gaat door wanneer er voldoende aanmeldingen en
                  trustees zijn. <br />
                  Dan wordt de online meeting-link om de Pitch Night online mee
                  te maken gedeeld.
                </p>
                <p className="text-base">
                  Idee inzenden, Trustee of partner worden?{' '}
                  <Link to="/frequently-asked-questions">Meld je aan!</Link>
                </p>
              </div>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default PitchNights2021Page;
