const nightsData = [
  {
    id: '2021_1',
    title: 'Pitch Night 1',
    eventDate: '2021-02-05 20:00',
    trustees_available: 20,
  },
  {
    id: '2021_2',
    title: 'Pitch Night 2',
    eventDate: '2021-04-01 20:00',
    trustees_available: 20,
  },
  {
    id: '2021_3',
    title: 'Pitch Night 3',
    eventDate: '2021-06-01 20:00',
    trustees_available: 20,
  },
  {
    id: '2021_4',
    title: 'Pitch Night 4',
    eventDate: '2021-08-01 20:00',
    trustees_available: 20,
  },
  {
    id: '2021_5',
    title: 'Pitch Night 5',
    eventDate: '2021-10-01 20:00',
    trustees_available: 20,
  },
  {
    id: '2021_6',
    title: 'Pitch Night 6',
    eventDate: '2021-12-01 20:00',
    trustees_available: 20,
  },
];

export default nightsData;
